const PRODUCTION = true;
const SOCKET_URL = (PRODUCTION) ? "https://messenger.taranstafford.co.uk" : "http://localhost:8080";

const UPLOADS_URL = SOCKET_URL + "/uploads/";

const THEME_COLOURS = [
  { color:'#009DDC', lbl:"Blue", class:"blue" },
  { color:'#0CCA4A', lbl:"Green", class:"green" },
  { color:'#F05D23', lbl:"Orange", class:"orange" },
  { color:'#F51AA4', lbl:"Pink", class:"pink" },
  { color:'#7E1F86', lbl:"Purple", class:"purple" },
  { color:'#FF0035', lbl:"Red", class:"red" },
  { color:'#EF6F6C', lbl:"Coral", class:"coral" },
  { color:'#253D5B', lbl:"Dark blue", class:"dark-blue" },
  { color:'#615D6C', lbl:"Grey", class:"grey" }
];

const DEFAULT_COLOUR = THEME_COLOURS[0];

const COLOR_FROM_HEX = (hex) => {
  let color = DEFAULT_COLOUR;
  THEME_COLOURS.forEach(c => {
    if (c.color.toLowerCase() === hex.toLowerCase()) {
      color = c;
    }
  })
  return color;
}

// sort users alphabetically - me at top
const SORT_USERS = (users, myID) => {
  users = users.sort((a, b) => {
    if (a.self) return -1;
    if (b.self) return 1;
    if (a.username < b.username) return -1;
    return a.username > b.username ? 1 : 0;
  });
  return users;
}

const MAX_ATTACHMENTS = 2;
const MAX_ATTACHMENT_FILE_SIZE = 5000000;
const ALLOWED_FILETYPES = ['image/jpeg','image/png','image/gif'];


const ENABLE_GAMES = false;

const constants = {
  SORT_USERS,
  SOCKET_URL,
  UPLOADS_URL,
	THEME_COLOURS,
	DEFAULT_COLOUR,
  COLOR_FROM_HEX,
  MAX_ATTACHMENTS,
  MAX_ATTACHMENT_FILE_SIZE,
  ALLOWED_FILETYPES,
  ENABLE_GAMES
};
export default constants;
