import React from 'react';

const UploadButton = ({ enabled, onChange }) => {

  let btnClass = (enabled) ? 'btn-share' : 'btn-share btn-share--disabled';
  return (
    <div className={btnClass}>
      <label htmlFor="image-upload">
        <i className="icon-picture"></i>
        <input
          type="file"
          accept="image/gif, image/jpeg, image/png"
          id="image-upload"
          name="attachment"
          onChange = {onChange}
          disabled = {!enabled}
        />
      </label>
    </div>
  );
}

export default UploadButton;
