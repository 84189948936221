import React from 'react';
import Constants from "../constants/Constants";

const MessageAttachment = ({ id, fileName, isLoaded, uploadFailed, onClick }) => {

  const handleClick = (ev) => {
    ev.preventDefault();
    onClick(id);
  }

  const imageStyle = {
    background: "url( " + Constants.UPLOADS_URL + fileName + ") no-repeat center",
    backgroundSize: "cover"
  }

  const loader = <div className="sk-circle">
    <div className="sk-circle1 sk-child"></div>
    <div className="sk-circle2 sk-child"></div>
    <div className="sk-circle3 sk-child"></div>
    <div className="sk-circle4 sk-child"></div>
    <div className="sk-circle5 sk-child"></div>
    <div className="sk-circle6 sk-child"></div>
    <div className="sk-circle7 sk-child"></div>
    <div className="sk-circle8 sk-child"></div>
    <div className="sk-circle9 sk-child"></div>
    <div className="sk-circle10 sk-child"></div>
    <div className="sk-circle11 sk-child"></div>
    <div className="sk-circle12 sk-child"></div>
  </div>;

  const image = <div className="image" style={imageStyle} ></div>;

  const failed = <div className="upload-failed"><i className="icon-attention-circled"></i></div>

  return (
    <div className="attachment attachment--image" >
      <div className="inner">
        { isLoaded ? image : (uploadFailed) ? failed : loader }
      </div>
      <button
        className="btn-close"
        onClick={ handleClick }
      ><i className="icon-cancel"></i></button>
    </div>
  );
}

export default MessageAttachment;
