const UserListItem = ({userID, username, self, connected, messages, channel, onClick, selected}) => {

  // vars
  // class name
  let cStyle = 'user-item';
  if (self) {
    cStyle = cStyle + ' user-item--self';
  }
  if (selected) {
    cStyle = cStyle + ' active';
  }

  // add me to my username
  let usernameLbl = (self) ?
    <span>{username} <small> (Me)</small></span> :
    username;

  // online status icon
  let status = (connected) ?
    <span className="user-status user-status--online">Online</span> :
    <span className="user-status user-status--offline">Offline</span>;

  // num unread message badge
  let numMessages = (channel && channel.numNewMessages) ?
    <span className="badge-num-messages">{channel.numNewMessages}</span> :
    '';


  // methods
  const click = () => {
    onClick(userID);
  }
  

  // render
  return (
    <div className={cStyle} onClick={click}>
      <div>
        <div className="user-item__name">{ usernameLbl }</div>
        <div className="user-item__meta">
          {status}{numMessages}
        </div>
      </div>
      <div className="user-item__actions">

      </div>
    </div>
  );
}

export default UserListItem;
