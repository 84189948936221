import React from 'react';
import { CirclePicker } from 'react-color';
import Constants from "../constants/Constants";
import LinesButton from "../components/LinesButton";
import UsernameCheckInput from "../components/UsernameCheckInput";


const UserSettings = ({username, theme, settingsOut, onUsernameChange, onThemeChange, onLeaveChat, onToggleSettings}) => {

  // state
  const [backgroundColor, setBackgroundColor] = React.useState(theme.color);
  const [usernameText, setUsernameText] = React.useState(username);

  // vars
  let colors = Constants.THEME_COLOURS.map(c => c.color);

  // methods
  const handleUsernameChange = username => {
    setUsernameText(username);
    onUsernameChange(username);
  }

  const handleChangeComplete = (color) => {
    setBackgroundColor(color.hex);
    onThemeChange(color.hex);
  };

  const handleLeaveChat = () => {
    onLeaveChat();
  };

  // render
  return (
    <div className="settings">
      <div className="settings__inner">
        <div className="settings__header">
          <span>Settings</span>
        </div>
        <div className="settings__lbl">
          Display name:
        </div>
        <div className="settings__input">
          <UsernameCheckInput
            username={ usernameText }
            useSave={ true }
            onChange={ handleUsernameChange }
          />
        </div>
        <div className="settings__lbl">
          Theme:
        </div>
        <div className="settings__input">
          <CirclePicker
            color={ backgroundColor }
            colors={ colors }
            circleSize={30}
            circleSpacing={12}
            width="229px"
            onChangeComplete={ handleChangeComplete }
          />
        </div>
        <div className="btn-wrap">
          <button className="btn btn-leavechat" onClick={ handleLeaveChat }>Leave Chat</button>
        </div>
        <LinesButton
          active={settingsOut}
          onClick={onToggleSettings}
        />
      </div>
    </div>
  )
}

export default UserSettings;
