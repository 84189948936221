import React, { useState } from 'react';
import Constants from "../constants/Constants";

const UsernameCheckInput = ({ username, useSave = false, onChange }) => {

  const [_usernameText, setUsernameText] = useState(username);
  const [_isUsernameAvailable, setIsUsernameAvailable] = useState(true);

  const handleInput = (ev) => {
    ev.preventDefault();

    let newUsername = ev.target.value;

    // remove spaces
    newUsername = newUsername.replace(/\s/g, '');

    // remove linebreaks
    newUsername = newUsername.replace(/(\r\n|\n|\r)/gm, "");

    setUsernameText(newUsername);

    fetch(Constants.SOCKET_URL + '/checkUsernameAvailable?u='+newUsername).then(async response => {
      let data = await response.json();
      console.log(data);
      setIsUsernameAvailable(data.available);
      if (data.available && !useSave) {
        onChange(newUsername);
      }
    });
  }

  const handleKeyDown = ev => {
    if (ev.key === 'Enter' && useSave) {
      ev.preventDefault();
      if (_isUsernameAvailable && _usernameText !== username) {
        onChange(_usernameText);
      }
    }
  }

  const handleSave = (ev) => {
    ev.preventDefault();
    if (_isUsernameAvailable && _usernameText !== username) {
      onChange(_usernameText);
    }
  }

  let uClass = "username-select";

  if (_usernameText !== username) {
    uClass += (_isUsernameAvailable) ? " username-select--valid" : " username-select--error";
  }

  if (useSave) {
    uClass = uClass + " hasbtn";
  }

  return (
    <div className={uClass}>
      <div className="inner">
        <div className="input-wrap">
          <input
            type="text"
            onChange={ handleInput }
            onKeyDown={ handleKeyDown }
            value={ _usernameText }
            autoComplete="off"
            />
        </div>
        { useSave &&
          <button
            className="btn btn-save-username"
            disabled={!(_isUsernameAvailable && _usernameText !== username)}
            onClick={ handleSave }
          >Save</button>
        }
      </div>
      {(!_isUsernameAvailable && _usernameText !== username) && <span className="error-msg"><i className="icon-attention"></i> Display name taken.</span> }
    </div>
  );
}

export default UsernameCheckInput;
