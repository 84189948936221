// import React from "react";
import ChannelListItem from "./ChannelListItem";
import UserListItem from "./UserListItem";
import Expander from "../components/Expander";


const ChannelList = ({channel, channels, privateChannels, users, username, userID, theme, onSelectChannel, onPrivateChat, onUsernameChange, onThemeChange}) => {

  // vars

  // init content vars with no content lbls
  let channelsContent = <div key="cWarning" className="no-content-message">There are no channels to show</div>;
  //let userChannelsContent = <div className="no-content-message">There are no channels to show</div>;
  let usersContent = <div key="pWarning" className="no-content-message">People could not be loaded</div>;


  // methods
  const handleChannelSelect = id => {
    onSelectChannel(id);
  }

  const handlePrivateChat = id => {
    onPrivateChat(id);
  }

  // const handleNewChannel = id => {
  //   console.log('new channel');
  // }

  // channels content
  if (channels && channels.length) {
    channelsContent = channels.map(c => <ChannelListItem
      key={c.id}
      {...c}
      onClick={handleChannelSelect}
      selected={ (channel && c.id === channel.id) }
    />);
  }

  // user channels content
  // if (userChannelsContent && userChannelsContent.length) {
  //   userChannelsContent = userChannelsContent.map(c => <Channel
  //     key={c.id}
  //     id={c.id}
  //     name={c.name}
  //     participants={c.participants}
  //     author={c.author}
  //     onClick={handleChannelSelect}
  //     selected={ (channel && c.id === channel.id) }
  //   />);
  // }

  // users content
  if (users && users.length) {
    usersContent = users.map(c => {
      let found = privateChannels.filter(e => e.id === c.userID);
      let channel = (found.length) ? found[0] : null;
      return (
        <UserListItem
          key={c.userID}
          {...c}
          channel={channel}
          onClick={handlePrivateChat}
          selected={ (channel && c.id === channel.id) }
        />
      )
    });
  }


  // render
  return (
    <div className="channel-panel">
      <div className="channel-panel__topbar">
        <img src="/logo.png" alt="TChat logo" className="logo" /> <h1>messenger</h1>
      </div>
      <div className="channel-panel__inner">
        <Expander
          title="Channels"
          content={channelsContent}
          expanded = {true}
          expanderClass = "channel-panel__item"
          headClass = "channel-panel__header"
          contentClass = "channel-list"
        />
        {/* <Expander
          title="User Channels"
          content={userChannelsContent}
          expanderClass = "channel-panel__item"
          headClass = "channel-panel__header"
          contentClass = "channel-list"
        /> */}
        <Expander
          title="People"
          content={usersContent}
          expanderClass = "channel-panel__item"
          headClass = "channel-panel__header"
          contentClass = "channel-list"
        />
      </div>
      {/* <div className="channel-panel__add">
        <div className="btn-add-channel" onClick={handleNewChannel} >
          <span>Add a Channel</span><i className="icon-plus"></i>
        </div>
      </div> */}
    </div>
  );
}

export default ChannelList;
