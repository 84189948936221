const ChannelListItem = (props) => {

  const {id, name, participants, bots, author, onClick, selected} = props;
  //const {isPrivate, sockets} = props;

  // vars
  // class name
  let cStyle = 'channel-item';
  if (selected) {
    cStyle = cStyle + ' active';
  }

  // num bots icon
  let botsLbl = (bots) ?
    <span><i className="icon-chatbot"></i>{bots}</span> :
    '';

  // author lbl
  let authorLbl = (author) ?
    <span className="channel-author">Created by: <strong>{author}</strong></span> :
    '';

  // user icon class - if bot face is looking at that
  let userIcon = (bots) ? "icon-user2" : "icon-user";


  // methods
  const click = () => {
    onClick({
      id: id,
      author: (author) && author
    });
  }


  // render
  return (
    <div className={cStyle} onClick={click}>
      <div className="channel-item__name">{name}</div>
      <div className="channel-item__meta">
        { authorLbl }
        <span><i className={ userIcon }></i>{participants}</span>
        { botsLbl }
      </div>
    </div>
  );
}

export default ChannelListItem;
