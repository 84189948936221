import React, { useState, useCallback } from "react";
import Message from './Message';
import UploadButton from "../components/UploadButton";
import MessageAttachment from "../components/MessageAttachment";
import Constants from "../constants/Constants";
import { SRLWrapper } from "simple-react-lightbox";

const MessagesPanel = ({channel, userID, username, onSendMessage, onClearChannel}) => {

  // state
  const [_messageText, setMessageText] = useState('');
  const [_shiftDown, setShiftDown] = useState(false);
  const [_attachments, setAttachments] = useState([]);
  const [_showMaxWarning, setShowMaxWarning] = useState(false);
  const [_showFileTypeWarning, setShowFileTypeWarning] = useState(false);
  const [_showDuplicateWarning, setShowDuplicateWarning] = useState(false);

  // vars

  const lightboxOptions = {
    settings: {
      autoplaySpeed: 0,
      disablePanzoom: true
    },
    buttons: {
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false
    },
    caption: {
      showCaption: false
    }
  }

  let channelName = <div className="no-content-message"><i className="icon-arrow-left"></i> Choose a channel to start chatting</div>;

  if (channel) {

    let status = "";

    if (channel.user) {
      status = (channel.user.connected) ?
        <span className="user-status user-status--online">Online</span> :
        <span className="user-status user-status--offline">Offline</span>;
    }

    channelName = <div>
      <span className="channel-name__header">{ channel.name }</span>
      { status }
    </div>;
  }


  let list = (!channel) ?
    <div className="no-content-message">No channel selected</div> :
    <div className="no-content-message">There are no messages to show</div>;


  // methods

  const send = () => {
    if (!channel) {
      alert('No channel selected');
      return;
    }
    // check not empty string ( inc new lines )
    if (_attachments.length || (_messageText && (_messageText.replace(/(\r\n|\n|\r)/gm, "") !== ''))) {
      onSendMessage(
        channel,
        _messageText,
        _attachments
      );
      setMessageText("");
      setAttachments([]);
    }
    else {
      console.log('no text');
    }
  }

  const playGame = () => {

  }

  const handleImageSelected = useCallback((ev) => {

    setShowMaxWarning(false);
    setShowFileTypeWarning(false);
    setShowDuplicateWarning(false);

    let attachments = _attachments.slice();

    if (attachments.length >= Constants.MAX_ATTACHMENTS) {
      return;
    }

    if (ev.target.files.length) {

      var formData = new FormData();
      let file = ev.target.files[0];
      formData.append('file', file);

      if (file.size > Constants.MAX_ATTACHMENT_FILE_SIZE) {
        setShowMaxWarning(true);
        return;
      }

      if (!Constants.ALLOWED_FILETYPES.includes(file.type)) {
        setShowFileTypeWarning(true);
        return;
      }

      if (attachments.filter(a => a.originalFileName === file.name).length) {
        setShowDuplicateWarning(true);
        return;
      }

      // read file and make base 64 string
      // let reader = new FileReader();
      // reader.onload = function(evt){
      //   attachments.push({
      //     id: attachments.length + 1,
      //     file: evt.target.result,
      //     fileName: data.name,
      //     isLoaded: false,
      //     uploadFailed: false
      //   });
      //   setAttachments(attachments);
      // };
      // reader.readAsDataURL(data);

      attachments.push({
        id: attachments.length + 1,
        originalFileName: file.name,
        fileName: "",
        isLoaded: false,
        uploadFailed: false
      });
      setAttachments(attachments);

      // set form data attributes
      formData.append('userID', userID);
      formData.append('channel', channel);
      formData.append('originalFileName', file.name);


      fetch(Constants.SOCKET_URL + '/uploadAttachment', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: formData
      }).then(
        response => response.json() // if the response is a JSON object
      ).then(
        result => {
          attachments.forEach((a) => {
            if (a.originalFileName === result.originalFileName) {
              a.fileName = result.filename;
              a.isLoaded = true;
            }
          });
          setAttachments([...attachments]); // add items to new array to force component refresh
        }
      ).catch(
        error => {
          console.log(error) // Handle the error response object
          attachments.forEach((a) => {
            if (a.originalFileName === file.name) {
              a.uploadFailed = true;
            }
          });
          setAttachments([...attachments]);
        }
      );

    }

  }, [_attachments, channel, userID]);

  const removeAttachment =  useCallback((id) => {

    setShowMaxWarning(false);
    setShowFileTypeWarning(false);
    setShowDuplicateWarning(false);

    let attachments = _attachments.slice();

    setAttachments(attachments.filter(a => a.id !== id));

  }, [_attachments]);

  const handleKeyDown = ev => {
    if (ev.key === 'Shift') {
      setShiftDown(true);
    }
    if (ev.key === 'Enter' && !_shiftDown) {
      ev.preventDefault();
      send();
    }
  }

  const handleKeyUp = ev => {
    if (ev.key === 'Shift') {
      setShiftDown(false);
    }
  }

  const handleInput = ev => {
    setMessageText(ev.target.value)
  }


  // vars

  // create message array
  if (channel && channel.messages) {

    let user = null;
    let count = null;
    let index = 0;
    list = [];

    channel.messages.forEach(m => {

      let mStyle = 'start'; // mid || end || single

      if (!user || user !== m.sender) {
        user = m.sender;
        count = 0;
      }
      else {
        count++;
      }

      if (index < channel.messages.length - 1) {
        let nextIndex = index+1
        let next = channel.messages[nextIndex];
        if (user !== next.sender) {
          mStyle = (count) ? 'end' : 'single';
        }
        else {
          mStyle = (count) ? 'mid' : 'start';
        }
      }
      else {
        mStyle = (count) ? 'end' : 'single';
      }

      list.push(
        <Message
          key={m.id}
          {...m} // add all properties from object
          isPrivate={channel.isPrivate}
          username={username}
          userID={userID}
          mStyle={mStyle}
        />
      );

      index++;
    });
  }

  let messageAttachments = [];
  if (_attachments.length) {
    _attachments.forEach(a => {
      messageAttachments.push(<MessageAttachment
        key={ a.id }
        { ...a }
        onClick={ removeAttachment }
      />);
    });
  }
  let shareBtnEnabled = (_attachments.length < Constants.MAX_ATTACHMENTS);

  // add games button if private chat
  let btnShare = (channel && channel.isPrivate) ?
    <UploadButton
      enabled={shareBtnEnabled}
      onChange={handleImageSelected}
      /> :
    "";

  let btnGames = (channel && channel.isPrivate && Constants.ENABLE_GAMES) ?
    <button className="btn-games" onClick={playGame}><i className="icon-gamepad" disabled={ (!channel )}></i></button> :
    "";

  let chatroomWarningMessage = (channel && !channel.isPrivate) ? <div className="chatroom-terms"><i className="icon-attention"></i> This chat room is unmoderated and for demo purposes only. Please do not post any personal information or offensive content. </div> : "";

  // render
  return (
    <div className="messages-panel">
      <div className="message-topbar">
        <button className="btn-clearChannel" onClick={ onClearChannel }><i className="icon-arrow-left"></i></button>
        <span className="channel-name">{ channelName }</span>
      </div>
      { chatroomWarningMessage }
      <div className="message-list">
        <div className="message-list__inner">
          <SRLWrapper options={ lightboxOptions }>
            { list }
          </SRLWrapper>
        </div>
      </div>
      { (channel) &&
        <div className="message-input">
          <div className="col-message">
            { (_attachments.length > 0) &&
              <div className="message-attachments">
                { messageAttachments }
              </div>
            }
            <div className="message-input__msg">
              <div className="msg-wrap">
                <textarea onChange={ handleInput } onKeyDown={ handleKeyDown } onKeyUp={ handleKeyUp } value={ _messageText } />
              </div>
              <button className="btn btn-send" onClick={ send } disabled={ ( !channel )}>
                <i className="icon-angle-right"></i><span className="visually-hidden">Send</span>
              </button>
            </div>
          </div>
          <div className="col-actions">
            <div className="message-input__btns">
              {btnShare}
              { _showMaxWarning && <span className="error-msg"><i className="icon-attention"></i> Max 5MB</span> }
              { _showFileTypeWarning && <span className="error-msg"><i className="icon-attention"></i> JPG, PNG or GIF</span> }
              { _showDuplicateWarning && <span className="error-msg"><i className="icon-attention"></i> Image already attached</span> }
              {btnGames}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MessagesPanel;
