import React, {useState, useContext, useCallback, useEffect}  from "react";
import ChannelList from "./ChannelList";
import MessagesPanel from "./MessagesPanel";
import UserSettings from "./UserSettings";
import Constants from "../constants/Constants";
import {SocketContext} from '../connection/socket';
//import "../style/chat.scss";

const Chat = () => {

  const socket = useContext(SocketContext);

  /* *** state *** */

  const [_users, setUsers] = useState([]);
  const [_channels, setChannels] = useState([]);
  const [_privateChannels, setPrivateChannels] = useState([]);
  const [_channel, setChannel] = useState(null);
  const [_username, setUsername] = useState((localStorage.getItem('tschat-username')) ? localStorage.getItem('tschat-username') : '');
  const [_theme, seTheme] = useState((localStorage.getItem('tschat-theme')) ? JSON.parse(localStorage.getItem('tschat-theme')) : Constants.DEFAULT_COLOUR);
  const [_settingsOut, setSettingsOut] = useState(false);


  const _userID = (localStorage.getItem('tschat-userID')) ? localStorage.getItem('tschat-userID') : ''
  const _sessionID = (localStorage.getItem('tschat-sessionID')) ? localStorage.getItem('tschat-sessionID') : null


  // socket connect
  const handleSocketConnect = useCallback(() => {
    //console.log('handleSocketConnect');
    // if lost connection make me offline
    let users = _users.slice();
    users.forEach((user) => {
      if (user.self) {
        user.connected = true;
      }
    });
    users = Constants.SORT_USERS(users, _userID);
    setUsers(users);

  }, [_users, _userID]);
  // socket disconnect
  const handleSocketDisonnect = useCallback(() => {
    //console.log('handleSocketDisonnect');

    // if lost connection make me offline
    let users = _users.slice();
    users.forEach((user) => {
      if (user.self) {
        user.connected = false;
      }
    });

    users = Constants.SORT_USERS(users, _userID);
    setUsers(users);

  }, [_users, _userID]);

  // socket  connection effects
  useEffect(() => {
    //console.log('useEffect connection');
    socket.on("connect", handleSocketConnect);
    socket.on("disconnect", handleSocketDisonnect);
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [socket, handleSocketConnect, handleSocketDisonnect]);


  // user handlers

  // user connected
  const handleUserConnected = useCallback((user) => {

    //console.log('handleUserConnected', user);

    // clone users array
    let users = _users.slice();

    // check if user in list - if not add
    let found = false;
    users.forEach((u) => {
      if (user.userID === u.userID) {
        u.connected = true;
        found = true;
      }
    });
    if (!found)  {
      users.push(user);
    }

    //sort users
    users = Constants.SORT_USERS(users, _userID);

    // update state
    setUsers(users);

  }, [_users, _userID]);

  // user disconnected
  const handleUserDisonnected = useCallback((userID) => {

    // clone users array
    let users = _users.slice();

    // find user in list and mark as disconnected
    users.forEach((user) => {
      if (user.userID === userID) {
        user.connected = false;
      }
    });

    // update state
    setUsers(users);

  }, [_users]);

  // user udated on server
  const handleUserUpdated = useCallback((user) => {

    // clone users array
    let users = _users.slice();

    // find user in list and update username
    users.forEach((u) => {
      if (user.userID === u.userID) {
        u.username = user.username;
      }
    });

    //sort users
    users = Constants.SORT_USERS(users, _userID);

    // update state
    setUsers(users);

  }, [_users, _userID]);

  // user left chat
  const handleUserLeftChat = useCallback((userID) => {

    // clone users array
    let users = _users.slice();

    // find user in list and remove
    users = users.filter(x => {
      return x.userID !== userID;
    });

    // update state
    setUsers(users);

  }, [_users]);

  useEffect(() => {
    //console.log('useEffect user');
    socket.on('user-connected',handleUserConnected);
    socket.on('user-disconnected',handleUserDisonnected);
    socket.on('user-updated',handleUserUpdated);
    socket.on('user-left-chat',handleUserLeftChat);
    return () => {
      socket.off('user-connected');
      socket.off('user-disconnected');
      socket.off('user-updated');
      socket.off('user-left-chat');
    };
  }, [socket, handleUserConnected, handleUserDisonnected, handleUserUpdated, handleUserLeftChat]);


  // channels handlers
  const handleChannelList = useCallback((channel) => {
    let channels = _channels.slice();
    channels.forEach(c => {
      if (c.id === channel.id) {
        c.participants = channel.participants;
      }
    });
    setChannels(channels);
  }, [_channels]);

  // channel effects
  useEffect(() => {
    //console.log('useEffect channel');
    socket.on('channel',handleChannelList);
    return () => {
      socket.off('channel');
    };
  }, [socket, handleChannelList]);



  // list users handler
  const handleUsersList = useCallback((users) => {
    //console.log('handleUsersList');
    // add self property
    users.forEach((user) => {
      user.self = user.userID === _userID;
    });

    Constants.SORT_USERS(users, _userID);

    setUsers(users);
  }, [_userID]);

  // list users effect
  useEffect(() => {
    //console.log('useEffect users');
    socket.on('users', handleUsersList);
    return () => {
      socket.off('users');
    };
  }, [socket, handleUsersList]);



  // message handler
  const handleNewMessage = useCallback((message) => {
    let channels = _channels.slice();
    channels.forEach(c => {
      if (c.id === message.channel_id) {
        if (!c.messages) {
          c.messages = [message];
        }
        else {
          c.messages.push(message);
        }
      }
    });
    setChannels(channels);
  }, [_channels]);

  // update message status
  const handleMessageRead = useCallback((message, dateRead) => {

    let privateChannels = _privateChannels.slice();
    privateChannels.forEach(c => {
      // find channel and add message
      if (c.id === message.to) {
        c.messages.forEach(m => {
          if (parseInt(m.id) === parseInt(message.id)) {
            m.is_read = true;
            m.date_read = dateRead;
          }
        });
      }
    });
    setPrivateChannels(privateChannels);

    if (_channel && _channel.id === message.to) {
      _channel.messages.forEach(m => {
        if (parseInt(m.id) === parseInt(message.id)) {
          m.is_read = true;
          m.date_read = dateRead;
        }
      });
    }
    setChannel(_channel);
  }, [_channel, _privateChannels]);

  // message effect
  useEffect(() => {
    //console.log('useEffect message');
    socket.on('message',handleNewMessage);
    socket.on('message-read',handleMessageRead);
    return () => {
      socket.off('message');
      socket.off('message-read');
    };
  }, [socket, handleNewMessage, handleMessageRead]);


  // use effect to update local storage when value changes
  useEffect(() => {
    localStorage.setItem('tschat-username', _username);
  }, [_username]);


  /* *** vars *** */
  let chatClass = 'chat-app chat-app--' + _theme.class;
  if (_channel) {
    chatClass = chatClass + ' chat-app--state-chat'
  }
  if (_settingsOut) {
    chatClass = chatClass + ' settings-out'
  }


  /* *** methods *** */

  const getUser = useCallback((userID) => {
    let usersClone = _users.slice();
    let index = usersClone.findIndex(e => e.userID === userID);
    if (index > -1) {
      return usersClone[index];
    }
    return false;
  }, [_users]);

  const getPrivateChannel = useCallback((userID) => {

    //console.log("getPrivateChannel", userID);
    let user = getUser(userID);
    let channel;

    if (!user) {
      return false;
    }

    let privateChannels = _privateChannels.slice();
    let found = privateChannels.filter(e => e.id === userID);
    if (!found.length) {
      //console.log('add new channel');
      channel = {
        id: userID,
        name: user.username,
        user: user,
        numNewMessages: 0,
        messages: [],
        isPrivate: true,
        date_read: 0,
        is_sent: false,
        is_read: false,
      }
      privateChannels.push(channel);
    }
    else {
      channel = found[0];
    }

    setPrivateChannels(privateChannels);

    return channel;
  }, [_privateChannels, getUser]);

  // load channels
  useEffect(() => {

    fetch(Constants.SOCKET_URL + '/getChannels')
      .then(async response => {
        let data = await response.json();
        //console.log(data.channels);
        setChannels(data.channels);
      })
      .catch(err => {
        //throw new Error(err)
        console.log('Error getting channels');
      });

    fetch(Constants.SOCKET_URL + '/getUsers?sessionID='+_sessionID).then(async response => {
      let data = await response.json();

      let users = data.users;
      let privateChannels = [];

      // add self property
      users.forEach((user) => {
        user.self = user.userID === _userID;

        // create channel and add messages
        if ( user.userID !== _userID) {
          let channel = {
            id: user.userID,
            name: user.username,
            user: user,
            numNewMessages: user.messages.filter(m => (!m.is_read && m.sender === user.userID)).length,
            messages: user.messages,
            isPrivate: true
          }

          //console.log(user);

          privateChannels.push(channel);
        }
      })

      Constants.SORT_USERS(users, _userID);
      setUsers(users);
      setPrivateChannels(privateChannels);
    })
    .catch(err => {
      //throw new Error(err)
      console.log('Error getting users');
    });;

  }, [_userID, _sessionID]);

  const savePrivateMessage = useCallback((message) => {

    // get private channels
    let privateChannels = _privateChannels.slice();

    // init private channel if not exists
    let found = privateChannels.filter(e => e.id === message.channel_id);
    if (!found.length) {

      let user = getUser(message.channel_id);
      if (!user) {
        return false;
      }

      let channel = {
        id: message.channel_id,
        name: user.username,
        user: user,
        numNewMessages: 0,
        messages: [],
        isPrivate: true
      }
      privateChannels.push(channel);
    }

    privateChannels.forEach(c => {

      // find channel and add message
      if (c.id === message.channel_id) {
        if (!c.messages) {
          c.messages = [message];
        }
        else {
          c.messages.push(message);
        }

        // if message channel is active - update state
        if (_channel && _channel.id === message.channel_id) {
          setChannel(c);
          if (!message.is_read && message.sender === message.channel_id) {
            socket.emit('message-read', message, Date.now());
          }
        }
        // if not add new messages marker
        else {
          c.numNewMessages++;
        }
      }
    });

    // save state
    setPrivateChannels(privateChannels);

  }, [socket, _privateChannels, _channel, getUser]);

  // private message effect
  useEffect(() => {
    //console.log('useEffect private message');
    socket.on('private-message', savePrivateMessage);
    return () => {
      socket.off('private-message');
    };
  }, [socket, savePrivateMessage]);

  /* *** event handlers *** */

  // channel events
  const handleChannelSelect = item => {
    //console.log(item);
    let channel = _channels.find(c => {
      return c.id === item.id;
    });
    setChannel(channel);
    socket.emit('channel-join', channel, ack => {});
  }

  const handlePrivateChat = userID => {
    // ignore if me
    if (userID === _userID) {
      return
    }

    socket.emit('channel-leave', _channel);

    // set up channel
    let pChannel = getPrivateChannel(userID);

    if (pChannel) {
      pChannel.messages.forEach(m => {
        if (!m.is_read && m.sender === userID) {
          socket.emit('message-read', m, Date.now());
        }
      });
      pChannel.numNewMessages = 0;
      setChannel(pChannel);
    }
  }

  // message events
  const handleSendMessage = (channel, text, attachments) => {
    if (channel.isPrivate) {
		
	  if (attachments.filter(a => a.isLoaded === false && a.uploadFailed === false).length) {
        console.log('Attachment still loading');
        return;
      }

      if (!attachments.filter(a => a.isLoaded === true).length && text === "") {
        console.log('Message empty');
        return;
      }

      let message = {
        id: Date.now(),
        channel_id: channel.id,
        text: text,
        to: channel.id,
        sender: _userID,
        senderName: (_username !== '') ? _username : _userID,
        attachments: attachments.filter(a => a.isLoaded === true)
      };

      // send message
      socket.emit('private-message',message);

      // save message
      savePrivateMessage(message);
    }
    else {
      socket.emit('send-message',{
        channel_id: channel.id,
        text: text,
        to: "",
        sender: _userID,
        senderName: (_username !== '') ? _username : socket.id,
        id: Date.now()
      });
    }

  }

  // settings events
  const handleToggleSettings = isOut => {
    setSettingsOut(isOut);
  }

  const handleUsernameChange = username => {
    setUsername(username);
    socket.emit('user-updated', username);

    // is set in useEffects above
    //localStorage.setItem('tschat-username', username);
  }

  const handleLeaveChat = () => {
    socket.emit('user-left-chat', _userID, _sessionID);
    localStorage.removeItem('tschat-userID');
    localStorage.removeItem('tschat-username');
    localStorage.removeItem('tschat-sessionID');
    localStorage.removeItem('tschat-theme');
    //socket.disconnect();
    window.location.reload(false);
  };

  const handleThemeChange = hex => {
    let theme = Constants.COLOR_FROM_HEX(hex);
    seTheme(theme);
    localStorage.setItem('tschat-theme', JSON.stringify(theme));
  }

  const handleClearChannel = hex => {
    socket.emit('channel-leave', _channel);
    setChannel(null);
  }


  /* *** render *** */

  return (
    <div className={chatClass}>
      <ChannelList
        channel={_channel}
        channels={_channels}
        privateChannels={_privateChannels}
        users={_users}
        username={_username}
        userID={_userID}
        theme={_theme}
        onSelectChannel={handleChannelSelect}
        onPrivateChat={handlePrivateChat}
        onUsernameChange={handleUsernameChange}
        onThemeChange={handleThemeChange}
      />
      <MessagesPanel
        channel={_channel}
        userID={_userID}
        username={_username}
        settingsOut={_settingsOut}
        onSendMessage={handleSendMessage}
        onClearChannel={handleClearChannel}
      />
      <div className="clickTrap"></div>
      <UserSettings
        username={_username}
        theme={_theme}
        settingsOut={_settingsOut}
        onToggleSettings={handleToggleSettings}
        onUsernameChange={handleUsernameChange}
        onThemeChange={handleThemeChange}
        onLeaveChat={handleLeaveChat}
      />
    </div>
  );
}

export default Chat;
