import React from 'react';

const LinesButton = ({active, onClick}) => {

  // state
  const [isActive, setIsActive] = React.useState(active);

  // vars
  let linesBtnClass = (isActive) ?
    'lines-button x active nav-close' :
    'lines-button x';

  // use effect to call function when value changes
  React.useEffect(() => {
    onClick(isActive);
  }, [isActive, onClick]);

  // methods
  const toggleSettings = ev => {
    ev.preventDefault();
    setIsActive(!isActive);

    // onClick(isActive);
    // use effect instead to call onClick, as var isnt updated
  }

  // render
  return (
    <button type="button" aria-label="Toggle Navigation" className={ linesBtnClass } onClick={ toggleSettings }>
      <span className="lines"></span>
    </button>
  );
}

export default LinesButton;
