import React from 'react';

const Expander = ({title, content, expanded, expanderClass, headClass, contentClass}) => {

  // state
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  // vars
  let expandedStyle = (isExpanded) ? {display:"block"} : {};
  let active = (isExpanded) ? " expander--expanded" : "";
  let exClassName = (expanderClass) ? 'expander ' + expanderClass + active : 'expander ' + active;
  let headClassName = (headClass) ? 'expander-head ' + headClass : 'expander-head';
  let contentClassName = (contentClass) ? 'expander-content ' + contentClass : 'expander-content ';

  // methods
  const toggleExpand = ev => {
    ev.preventDefault();
    setIsExpanded(!isExpanded);
  }

  // render
  return (
    <div className={exClassName}>
      <div className={headClassName} onClick={toggleExpand}>
        <span>{title}</span><div className="plusminus"></div>
      </div>
      <div className={contentClassName} style={expandedStyle}>
        {content}
      </div>
    </div>
  );
}

export default Expander;
