import React, {useState, useContext, useCallback, useEffect}  from "react";
import Constants from "../constants/Constants";

import {SocketContext} from '../connection/socket';

const Login = ({onUsernameSubmit}) => {

  const socket = useContext(SocketContext);

  // state
  const [_usernameText, setUsernameText] = useState('');
  const [_btnDisabled, setDtnDisabled] = useState(true);
  const [_serverConnected, setServerConnected] = useState(false);
  const [_usernameTaken, setUsernameTaken] = useState(false);

  // methods
  const start = useCallback((ev) => {
    console.log('start', _usernameText, _usernameTaken);
    if (_usernameText === "" || _usernameTaken) {
      return false;
    }
    onUsernameSubmit(_usernameText);
  }, [_usernameText, _usernameTaken, onUsernameSubmit]);

  const handleKeyDown = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      start();
    }
  }

  const handleKeyUp = useCallback(ev => {
    setDtnDisabled(_usernameText === '');
  }, [_usernameText]);

  const handleInput = ev => {

    let newUsername = ev.target.value;

    // remove spaces
    newUsername = newUsername.replace(/\s/g, '');

    // remove linebreaks
    newUsername = newUsername.replace(/(\r\n|\n|\r)/gm, "");

    setUsernameText(newUsername);
  }

  useEffect(() => {

    console.log(Constants.SOCKET_URL + '/checkUsernameAvailable?u='+_usernameText);
    fetch(Constants.SOCKET_URL + '/checkUsernameAvailable?u='+_usernameText)
      .then(async response => {
        let data = await response.json();
        console.log("#checkUsernameAvailable", data);
        setUsernameTaken(!data.available);
      })
      .catch(err => {
        //throw new Error(err)
        console.log('error',err.message);
      });
  }, [_usernameText]);


  // socket connect
  const handleSocketConnect = useCallback(() => {
    setServerConnected(true);
  }, []);

  // socket disconnect
  const handleSocketError = useCallback((err) => {
    if (err.message !== "invalid username") {
      setServerConnected(false);
    }
    else {
      setServerConnected(true);
    }
  }, []);

  useEffect(() => {
    //console.log('useEffect connection');
    socket.on('connection', handleSocketConnect);
    socket.on('connect_error', handleSocketError);
    return () => {
      socket.off("connection");
      socket.off("connect_error");
    };
  }, [socket, handleSocketConnect, handleSocketError]);

  // render
  return (
    <div className="login">
      <div className="login__modal">
        <div className="inner">
          <h1>Hello</h1>
          <p>Choose a display name to continue</p>

          <div className="form-row">
            <input
              type="text"
              onChange={ handleInput }
              onKeyDown={ handleKeyDown }
              onKeyUp={ handleKeyUp }
              value={ _usernameText }
              autoComplete="off"
            />
            {(_usernameTaken) ? <span className="error-msg error-msg--username"><i className="icon-attention"></i> Display name taken.</span> : "" }
          </div>
          <div className="form-row">
            <div className="btn-wrap">
              <button
                className="btn btn-start"
                onClick={start}
                disabled={ _btnDisabled || !_serverConnected || _usernameTaken }
              >Start Chatting</button>
            </div>
          </div>
          {(!_serverConnected) ? <span className="error-msg"><i className="icon-attention"></i> Server is offline. Please try again later.</span> : "" }
        </div>
      </div>
    </div>
  );
}

export default Login;
