import React, {useState, useCallback, useEffect} from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import './style.scss';

import Login from './login/Login';
import Chat from './chat/Chat';

import {SocketContext, socket} from './connection/socket';
import SimpleReactLightbox from 'simple-react-lightbox'

const App = () => {

  // TODO - use redux instead of singleton
  //let dataStore = DataManager

  //const pathname = window.location.pathname;
  //console.log( pathname , '*', localStorage.getItem('tschat-username'));


  const [_username, setUsername] = useState(localStorage.getItem('tschat-username'));
  const [_userID, setUserID] = useState(localStorage.getItem('tschat-userID'));
  const [_sessionID, setSessionID] = useState(localStorage.getItem('tschat-sessionID'));
  const [_sessionStarted, setSessionStarted] = useState(false);

  // use effect to update local storage when value changes
  useEffect(() => {
    if (_username !== null && _username !== '') {
      localStorage.setItem('tschat-username', _username);
    }
  }, [_username]);

  useEffect(() => {
    if (_userID !== null && _userID !== '') {
      localStorage.setItem('tschat-userID', _userID);
    }
  }, [_userID]);

  useEffect(() => {
    if (_sessionID !== null && _sessionID !== '') {
      localStorage.setItem('tschat-sessionID', _sessionID);
    }
  }, [_sessionID]);


  const connectToSocket = useCallback(() => {
    console.log('connectToSocket', _username);

    if (_username !== null && _username !== '') {
      socket.auth = {
        username: _username,
        sessionID: _sessionID
      };
      console.log('socket connect');
      socket.connect();
    }

  }, [_username, _sessionID]);

  const handleSocketSession = useCallback((data) => {
    console.log('handleSocketSession');

    setUsername(data.user.username);
    setUserID(data.user.userID);
    setSessionID(data.sessionID);
    setSessionStarted(true);

  }, []);

  // const handleSocketConnection = useCallback((err) => {
  //
  // }, []);

  const handleSocketError = useCallback((err) => {
    console.log('connect_error:', err.message);
    if (err.message === "Error: invalid username") {

    }
  }, []);

  useEffect(() => {

    connectToSocket();

    socket.on('session', handleSocketSession);
    //socket.on('connection', handleSocketConnection);
    socket.on('connect_error', handleSocketError);
    return () => {
      socket.off('session');
      socket.off('connection');
      socket.off('connect_error');
    };
  }, [connectToSocket, handleSocketSession, handleSocketError]);


  const handleUsernameSubmit = username => {
    console.log('handleUsernameSubmit', username);
    socket.auth = { username: username };
    socket.connect();
  }

  // redirect to login if no username
  if ( _username === null || _username === '') {
    console.log('return login', _username);
    return (
    <SocketContext.Provider value={socket}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login
                onUsernameSubmit = {handleUsernameSubmit}
              />
            </Route>
            <Redirect to="/login" />
          </Switch>
        </Router>
      </SocketContext.Provider>
    )
  }

  return (
    <React.StrictMode>
      <SocketContext.Provider value={socket}>
        <SimpleReactLightbox>
          <Router>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Redirect from="/login" to="/" />
              <Route path="/">
                { (!_sessionStarted) ?
                  <div className="loading-chat">
                    <div className="spinner">
                      <div className="cube1"></div>
                      <div className="cube2"></div>
                    </div>
                  </div> :
                  <Chat /> }
              </Route>
            </Switch>
          </Router>
        </SimpleReactLightbox>
      </SocketContext.Provider>
    </React.StrictMode>
  );
}

export default App;
