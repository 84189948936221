import React from "react";
import io from "socket.io-client";
import Constants from "../constants/Constants";

export const socket = io.connect(Constants.SOCKET_URL);

// disable reconnect
// export const socket = io.connect('http://server.com', {
//     reconnection: false
// });

export const SocketContext = React.createContext();
