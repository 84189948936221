import Constants from "../constants/Constants";

const Message = ({channel_id, id, mStyle, sender, senderName, text, userID, username, attachments, is_read, is_sent, date_read, isPrivate}) => {

  // class name
  let cName = 'message-item message-item--' + mStyle;

  // if me add class
  if (sender === userID) {
    cName = cName + ' message-item--me';
  }

  let messageEmpty = (text.replace(/(\r\n|\n|\r)/gm, "") === "");
  if (messageEmpty) {
    cName = cName + ' is-empty';
  }

  if (attachments && attachments.length) {
    cName = cName + ' has-attachments';
  }

  // date format
  let date = new Date(parseInt(id));
  //var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // meta
  let senderDiv = '';
  let timeDiv = '';
  let dateDiv = '';
  if (mStyle === 'end' || mStyle === 'single') {
    senderDiv = <div className="message-item__sender">{senderName}</div>;
    timeDiv = <div className="message-item__date">{
      date.toLocaleTimeString()
    }</div>;
    dateDiv = <div className="message-item__date">{
      date.toLocaleDateString("en-GB")
    }</div>;
  }

  // message read ticks
  let msgStatusSent = (true) ? " status-sent" : "";
  let msgStatusRead = (is_read) ? " status-read" : "";
  let msgStatus = <div className={"message-item__status" + msgStatusSent + msgStatusRead }>
    <span className="status-tick status-tick--sent"><i className="icon-check"></i></span>
    <span className="status-tick status-tick--read"><i className="icon-check"></i></span>
  </div>;

  // add line breaks in messages
  let textWithLinebreaks = text.split('\n').map(function(item, key) {
    return (
      <span key={key}>
        {item}
        <br/>
      </span>
    )
  });

  let messageAttachments = [];
  if (attachments && attachments.length) {
    attachments.forEach(a => {
      messageAttachments.push(<div key={ "a" + a.id } className={'message-item__attachment'}>
      <div className="inner">
        <img src={ Constants.UPLOADS_URL + a.fileName} alt={ "attachment #" + a.id } /></div>
      </div>);
    });
  }

  // render
  return (
    <div className={cName}>
      {messageAttachments}
      { !messageEmpty &&
        <div className="message-item__content">
          <div className="inner">
            { textWithLinebreaks }
            { isPrivate ? msgStatus : "" }
            <div className={ timeDiv }></div>
          </div>
        </div>
      }
      <div className="message-item__meta">
        { dateDiv }
        { senderDiv }
      </div>
    </div>
  );
}

export default Message;
